/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ControlTariff } from '@/store/tariff/types';
import router from "@/route/routing";

const namespace: string = 'tariff';
export default class SvtcTariffChoicePage extends Vue {
	@State('tariff') tariff: ControlTariff | undefined;
	@Action('fetchSvtcTariff', {namespace}) fetchSvtcTariff: any;
	@Action('storeSvtcTariff', {namespace}) storeSvtcTariff: any;
	@Getter('getSvtcTariff', {namespace}) getSvtcTariff: any;

    // $refs!: {
    //     mainMenu: HTMLInputElement,
    // };

	selectTariff(card: any) {
		this.storeSvtcTariff(card);
	}

	openMenu()
	{
        return (this.$route.query.menu == "tariffs");
	}

	mounted() {
        // console.log('Header mounted');
        //
        // console.log('this.$route.query.menu', this.$route.query.menu);
        // if (this.$route.query.menu == "tariffs")
        // {
        //     console.log("Открываем меню");
        //     const elem = this.$refs.mainMenu;
        //     elem.click();
        // }

        //console.log('this.$route.query.menu', this.$route.query.menu);

		//this.fetchSvtcTariff();
	}
}
